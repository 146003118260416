<script>
import calendar from "@/components/Calendars.vue";
import BackArrow from "../../../components/BackArrow/index.vue";
import ExcessBaggageModal from "./Components/ExcessBaggageModal";
import Helpers from "@/mixins";
export default {
  components: {
    calendar,
    BackArrow,
    ExcessBaggageModal,
  },
  mixins: [Helpers],
  data: () => ({
    showExcessBaggageModal: false,
    cancelDialog: false,
    childDialog: false,
    dialog: false,
    items: [],
    search: {
      typeSearch: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      document: "",
      bar_code: "",
      id_operador: 0,
    },
    tiposDocumento: [
      { id: "1", descricao: "RG" },
      { id: "2", descricao: "TÍTULO DE ELEITOR" },
      { id: "3", descricao: "PASSAPORTE" },
      { id: "4", descricao: "CNH" },
      { id: "5", descricao: "OUTROS" },
    ],
    crianca: null,
    dadosCrianca: {
      nome: "",
      tipo_documento: "",
      documento: "",
      tipo: "C",
    },
    clientes: [],
    cancelData: {
      xJust: "",
      tpAmb: "",
      chBPe: "",
      nProt: "",
      id_bpe: null,
      empresauf: "",
    },
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 5000,
    },
    dadosPassagem: [],
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
    itemSelected: {},
  }),
  created() {
    this.Helpers;
    this.initialize();
  },

  methods: {
    reprintExcessBagagge(excess_bagagge_id) {
      this.$store.dispatch("activeLoader", true);

      const postData = {
        id_excesso_bagagem: excess_bagagge_id,
      };

      this.$http
        .post("/pdv/vendas/excesso-bagagem", postData)
        .then((result) => {
          this.$store.dispatch("activeLoader", false);
          this.message.snacshow = true;
          this.message.type = "success";
          this.message.text = result.data.success;
        })
        .catch((error) => {
          this.$store.dispatch("activeLoader", false);
          this.message.snacshow = true;
          this.message.type = "error";
          this.message.text = error.message;
        });
    },
    showExcessBaggageModalAction(item) {
      this.itemSelected = item;
      this.showExcessBaggageModal = !this.showExcessBaggageModal;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.search.id_operador = this.$store.getters.getOperador;
      this.$http.post(`/pdv/vendas`, this.search).then((r) => {
        this.items = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getClientes() {
      this.$http.get(`/clientes/crianca`).then((r) => {
        this.clientes = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    clearForm() {
      (this.dadosCrianca.nome = ""),
        (this.dadosCrianca.tipo_documento = ""),
        (this.dadosCrianca.documento = "");
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/clientes/crianca/create", this.dadosCrianca)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.id) {
            this.getClientes();
            this.crianca = r.data.id;
            this.dialog = false;
            this.clearForm();
          } else {
            this.message.text = r.data.warning;
            this.message.snacshow = true;
            this.message.type = "error";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    reimpressao(id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/reimpressao`, {
          id_bpe: id,
          id_operador: this.$store.getters.getOperador,
        })
        .then((r) => {
          if (r.data.result) {
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "success";
            this.initialize();
          } else {
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "error";
          }
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    clearValues() {
      (this.search.document = ""), (this.search.bar_code = "");
    },
    editar(id) {
      this.$router.push(`/cadastros/formas-pagamento/editar/${id}`);
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    posVendaCrianca() {
      this.dadosPassagem.id_cliente = this.crianca;
      this.dadosPassagem.bpe = JSON.stringify(this.dadosPassagem.bpe);
      this.dadosPassagem.bpe = JSON.parse(this.dadosPassagem.bpe);
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/crianca/posvenda`, this.dadosPassagem)
        .then((r) => {
          if (r.data.result) {
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "success";
            this.crianca = null;
            this.$store.dispatch("activeLoader", false);
            this.initialize();
          } else {
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "error";
            this.$store.dispatch("activeLoader", false);
            this.initialize();
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    cancelarVenda() {
      this.$store.dispatch("activeLoader", true);
      let c = confirm("Deseja cancelar a venda?");
      if (c) {
        this.$http
          .post(`/cancelarvenda`, this.cancelData)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.result) {
              this.message.text = r.data.result;
              this.message.snacshow = true;
              this.message.type = "success";
              this.initialize();
              this.cancelDialog = false;
            } else {
              this.message.text = r.data.error;
              this.message.snacshow = true;
              this.message.type = "error";
            }
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "error";
          });
      } else {
        this.$store.dispatch("activeLoader", false);
      }
    },
  },
};
</script>
