<script>
import axios from "axios";
import Helpers from "@/mixins";
import { api2UrlByEnviroment } from "../../../../../utils";
export default {
  props: ["showModal", "dataBpe"],
  mixins: [Helpers],
  data: () => ({
    isLoading: false,
    message: {
      type: "",
      show: false,
      message: "",
    },
    showMenu: false,
    sendData: {
      baggageAmount: 0,
      serviceTotalValue: 0,
      bpeKey: "",
      protocolNumber: "",
      environmetType: "",
      dateHourEvent: "",
      paymentForm: null,
    },
    checkData: {},
    paymentForm: [],
  }),
  created() {
    this.Helpers;
    // this.sendData.dateHourEvent = this.formatDateToSendExcessBaggage(
    //   new Date(this.dataBpe.datahora).toISOString()
    // );

    this.getEnviromentType();
    this.getpaymentForm();
  },
  methods: {
    async sendExcessBaggage() {
      try {
        this.isLoading = true;

        const postData = {
          ...this.sendData,
        };

        postData.serviceTotalValue = parseFloat(postData.serviceTotalValue);
        this.sendData.baggageAmount = parseInt(this.sendData.baggageAmount);

        const response = await axios.post(
          `${
            api2UrlByEnviroment[process.env.NODE_ENV.trim()]
          }/send-excess-bagagge`,
          postData
        );

        const type = response.data.success ? "success" : "error";

        const message = response.data.success
          ? response.data.success
          : response.data.error;

        this.showMessage(type, message, true);
        this.$emit("initialize");
        if (!response.data.error) {
          this.showModalExcessBaggage();
          this.cleanFormData();
        }

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.showMessage("error", error, true);
      }
    },
    async getEnviromentType() {
      const { data } = await this.$http.get("configbpe");
      const { tipo_ambiente } = data[0];
      this.sendData.environmetType = tipo_ambiente;
    },
    showMessage(type, message, isShow) {
      this.message.type = type;
      this.message.message = message;
      this.message.show = isShow;
      setTimeout(() => {
        this.message = {
          type: "",
          show: false,
          message: "",
        };
      }, 4000);
    },
    cleanFormData() {
      this.isLoading = false;
      this.sendData = {
        ...this.sendData,
        baggageAmount: 0,
        serviceTotalValue: 0,
        paymentForm: null,
      };
    },
    showModalExcessBaggage() {
      this.$emit("showModalExcessBaggage");
      this.showFormExcessBaggage();
      this.cleanFormData();
    },
    getpaymentForm() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.paymentForm = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
  },
  watch: {
    showModal(value) {
      this.showMenu = value;
    },
    dataBpe(value) {
      if (value) {
        this.checkData = value;
        this.sendData.bpeKey = value.chBPe;
        this.sendData.protocolNumber = value.nProt;
        this.sendData.dateHourEvent = this.formatDateToSendExcessBaggage(
          value.datahora
        );
      }
    },
  },
};
</script>
